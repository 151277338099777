import store from "@/store"
import {ref} from "@vue/composition-api"

export default function useCompanyConfigList(props) {

	const searchQuery = ref("")
	const loading = ref(false)
	const options = ref({
		sortBy: ["id"],
		sortDesc: [true],
	})
	const allCompanyNames = ref([])

	// fetch data
	const fetchCompanies = () => {
		store
			.dispatch(props?.moduleName + "/fetchCompanies", {
				q: searchQuery.value,
				options: options.value,
				namesOnly: true
			})
			.then(response => {
				allCompanyNames.value = response.data;

				// remove loading state
				loading.value = false
			})
			.catch(error => {
				console.log(error)
			})
	}

	return {
		allCompanyNames,
		fetchCompanies
	}
}
